// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-accessibility-for-web-developers-mdx": () => import("./../src/pages/accessibility-for-web-developers.mdx" /* webpackChunkName: "component---src-pages-accessibility-for-web-developers-mdx" */),
  "component---src-pages-accessibility-js": () => import("./../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogroll-mdx": () => import("./../src/pages/blogroll.mdx" /* webpackChunkName: "component---src-pages-blogroll-mdx" */),
  "component---src-pages-courses-common-accessibility-mistakes-js": () => import("./../src/pages/courses/common-accessibility-mistakes.js" /* webpackChunkName: "component---src-pages-courses-common-accessibility-mistakes-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-templates-post-post-js": () => import("./../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */)
}

